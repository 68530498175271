<template>
  <div>
    <a-list v-if="data.length > 0" :dataSource="pageData">
      <a-list-item slot="renderItem" slot-scope="item, index">
        <a-row type="flex">
          <a-col>
            {{ (currentPage - 1) * defaultPageSize + index + 1 }}、
            <router-link v-bind:to="{ name: routeName, query:{ id:novel.id, index:  (currentPage - 1) * defaultPageSize + index + 1, name: novel.name, url: item.url }}">{{item.title}}              </router-link>
          </a-col>
          <a-col class="gutter-box">
            <a-tag
              color="#108ee9"
              v-if="browsingHistory(novel.id, item.url)">上次阅读
            </a-tag>
          </a-col>
        </a-row>
      </a-list-item>
    </a-list>

    <a-pagination
      size="small"
      v-model="currentPage"
      showQuickJumper
      :defaultCurrent="currentPage"
      :total="data.length"
      :defaultPageSize="defaultPageSize"
      :hideOnSinglePage="true"
      @change="onPageChange"
    />
    <a-skeleton v-if="loading" active />
    <a-back-top />
  </div>
</template>

<script>
import moment from "moment";
import util from "../utils/local";
const base_API_URL = "/api/novel/getChapters/";

export default {
  name: "chapters",
  data() {
    return {
      data: [],
      pageData: [],
      currentPage: 1,
      defaultPageSize: 10,
      novel: {
        id: this.$route.query.id,
        name: this.$route.query.name
      },
      loading: false,
      busy: false
    };
  },
  beforeCreate(){
    //console.log("beforeCreate");
  },

  created(){
    //将存储在localStorage中的页码设为当前页码,代码需要放在钩子回调中
    this.$nextTick(() => {
      this.currentPage = this.defaultCurrent;
    })
    //console.log("created => currentPage", this.currentPage);
    //console.log("created => currentPage", this.defaultCurrent);
  },

  beforeMount() {
    //console.log("beforeMount");
  },

  mounted()
  {
    this.getCacheData();
    this.onPageChange(this.defaultCurrent)
    //console.log("mounted");
  },

  computed: {
    indexText(index) {
      var index = 1;
      return index;
    },
    defaultCurrent() {
      return this.getPageHistory(this.novel.id);
    },
    storeNovel() {
      //获取store中的数据，需要将代码放置在computed中方可。
      return this.$store.state.novel;
    },
    routeName() {
      var settings = JSON.parse(localStorage.getItem("CustomSettings"));
      if (settings == null) {
        settings = this.$store.state.customSettings;
      }
      var path = settings.immersive ? "immersive" : "content";
      return path;
      /*
        var data = JSON.parse(localStorage.getItem("CustomSettings"));
        if(data!= null){
          this.$store.commit("setCustomSettings",data);
        }
        */
    }
  },
  watch: {},
  methods: {

    getCacheData() {
      var cache = JSON.parse(sessionStorage.getItem(this.novel.id));
      if (cache == null) {
        this.fetchData();
      } else {
        var expires = moment(cache.expires);

        if (moment().isBefore(expires)) {
          this.data = cache.data;
        } else {
          this.fetchData();
        }
      }
      document.title = this.$route.query.name;
    },

    fillPageData() {
      var start = 0;
      var end = this.defaultPageSize * this.currentPage;

      if (this.data.length <= this.defaultPageSize) {
        this.currentPage = 1;
        end = this.data.length;
      } else {
        if (this.currentPage == 1) {
          start = 0;
          end = this.defaultPageSize;
        } else {
          start = (this.currentPage - 1) * this.defaultPageSize;
        }
      }

      this.pageData = this.data.slice(start, end);
    },

    fetchData() {
      this.loading = true;
      this.$axios
        .get(base_API_URL, { params: { id: this.novel.id } })
        .then(res => {
          this.data = res.data.data;
          this.loading = false;

          //设置缓存,3小时候过期
          var cache = {
            expires: this.$moment().add(3,"h"),
            data: this.data
          };
          sessionStorage.setItem(this.novel.id, JSON.stringify(cache));

          this.fillPageData();
        })
        .catch(error => {
          console.warn(error);
        });
    },

    browsingHistory(id, url) {
      var files = url.split("/");
      var file = files[files.length - 1];

      var histories = JSON.parse(localStorage.getItem("History"));
      if (histories == null) {
        return false;
      }
      return (
        histories.filter((x, index) => {
          return x.id == id && x.file == file;
        }).length > 0
      );
    },

    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
      this.fillPageData();
      this.setPageHistory(pageNumber);
    },

    //从localStorage中获取浏览记录
    getPageHistory(id) {
      var histories = JSON.parse(localStorage.getItem("PageHistory"));
      if (histories == null) {
        return 1;
      }
      var currentPageHistories = histories.filter((x, index) => {
        return x.id == id;
      });

      if (currentPageHistories.length == 0) {
        return 1;
      } else {
        this.currentPage = currentPageHistories[0].pageNumber;
        return currentPageHistories[0].pageNumber;
      }
    },

    setPageHistory(pageNumber) {
      var histories = [];

      if (localStorage.getItem("PageHistory") == null) {
        console.log("empty")
        histories.concat({ id: this.novel.id, pageNumber: pageNumber });
        localStorage.setItem("PageHistory", JSON.stringify(histories));
      }

      histories = JSON.parse(localStorage.getItem("PageHistory"));

      //把和当前阅读小说不符合的histories数组过滤出来。
      var filterData = histories.filter(item => item.id != this.novel.id);

      histories = filterData.concat({
        id: this.novel.id,
        pageNumber: pageNumber
      });

      localStorage.setItem("PageHistory", JSON.stringify(histories));
    }
  }
};
</script>
<style scoped>
.gutter-box {
  padding: 0 0 0 5px;
}
</style>